import React from 'react';
import { useTranslation } from 'react-i18next';

import Confirm from '@common/components/confirm-button';
import Icon from '@common/components/icon';
import Dropdown from '@common/components/dropdown';
import GroupConversationForm from '@modules/chat/forms/edit-group-conversation';
import { EConversationTypes } from '@modules/chat/definitions';

import { FullConversation, PrivateMessage } from '@modules/chat/types/objects';
import { User, UserStatus } from '@common/types/objects';
import { ConversationFormPayload } from '@modules/chat/forms/conversation/conversation';

interface ConversationActionsProps {
  conversation: FullConversation;
  usersStatus: Record<string, UserStatus | null>;
  alignRight?: boolean;
  onToggleNotifications: (id: string, shouldMute: boolean) => void;
  onArchive: (id: string) => void;
  onLeave: (id: string) => void;
  onUpdateConversation: (id: string, data: ConversationFormPayload) => void;
  onRemoveConversation: (id: string) => void;
  onOpenProfile: (userId: string) => void;
  onEditGroupAdmin: (conversationId: string, user: User, add: boolean) => void;
  onEditParticipants: (
    conversationId: string, activities: PrivateMessage | PrivateMessage[], participants: User | User[],
  ) => void;
}

const ConversationActions = ({
  conversation, usersStatus, alignRight = true,
  onToggleNotifications, onArchive, onLeave, onUpdateConversation,
  onRemoveConversation, onOpenProfile, onEditGroupAdmin, onEditParticipants,
}: ConversationActionsProps) => {
  const { t } = useTranslation();

  if (conversation.has_left) {
    return (
      <Confirm
        title={t('chat:conversation_confirm_archive_title')}
        description={t('chat:conversation_confirm_archive_description')}
        onConfirm={() => onArchive(conversation.id)}
      >
        <Icon type="inventory" className="ConversationActions" />
      </Confirm>
    );
  }

  return (
    <Dropdown
      alignRight={alignRight}
      id="card-dropdown"
      toggle={(
        <Icon
          type="more_vert"
          className="ConversationActions"
        />
      )}
    >
      {conversation.conversation_type === EConversationTypes.GROUP && (
        <GroupConversationForm
          usersStatus={usersStatus}
          conversation={conversation}
          onOpenProfile={onOpenProfile}
          onLeave={onLeave}
          onEditGroupAdmin={onEditGroupAdmin}
          onEditParticipants={onEditParticipants}
          onUpdateConversation={onUpdateConversation}
        >
          <Dropdown.Item>
            {t('chat:conversation_group_information')}
          </Dropdown.Item>
        </GroupConversationForm>
      )}
      {conversation.conversation_type === EConversationTypes.PRIVATE && conversation.participant && (
        <Dropdown.Item onClick={() => conversation.participant?.id && onOpenProfile(conversation.participant?.id)}>
          {t('chat:conversation_participant_open_profile')}
        </Dropdown.Item>
      )}
      <Dropdown.Item onClick={() => onToggleNotifications(conversation.id, !conversation.is_muted)}>
        {conversation.is_muted ? t('chat:conversation_enable_notifications') : t('chat:conversation_disable_notifications')}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Confirm
        title={t('chat:conversation_confirm_archive_title')}
        description={t('chat:conversation_confirm_archive_description')}
        onConfirm={() => onArchive(conversation.id)}
      >
        <Dropdown.Item>
          {t('chat:conversation_archive_conversation')}
        </Dropdown.Item>
      </Confirm>
      {conversation.conversation_type === EConversationTypes.GROUP && (
        <Confirm
          title={t('chat:conversation_form_confirm_leave_group')}
          description={conversation.is_admin && conversation.admin_ids.length === 1
            ? t('chat:conversation_form_confirm_leave_group_description_admin')
            : t('chat:conversation_form_confirm_leave_group_description')}
          onConfirm={() => onLeave(conversation.id)}
        >
          <Dropdown.Item danger>
            {t('chat:conversation_leave_conversation')}
          </Dropdown.Item>
        </Confirm>
      )}
      <Confirm
        title={t('chat:conversation_remove_confirm_title')}
        onConfirm={() => onRemoveConversation(conversation.id)}
      >
        <Dropdown.Item danger>
          {t('chat:remove_conversation')}
        </Dropdown.Item>
      </Confirm>
    </Dropdown>
  );
};

export default ConversationActions;
